import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import { IMergedReward, IReward } from "../../types/gatsby";
import PlanetLeaf from "../PlanetLeaf";

export interface RewardCardProps {
  reward: IReward;
  partner: IMergedReward["partner"];
}

const RewardCard: React.FC<RewardCardProps> = ({ reward, partner }) => {
  return (
    <Link
      to={`/our-partners/offer/${reward.rewardId}`}
      className="relative flex flex-col col-span-6 p-4 transition-shadow duration-300 bg-white shadow-lg cursor-pointer rounded-2xl hover:shadow-xl md:col-span-3 lg:col-span-2"
    >
      {reward.localImage[0] && (
        <GatsbyImage
          image={reward.localImage[0].childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          className="w-full h-48 mb-4 rounded-lg"
          imgClassName="rounded-lg"
          alt={`${partner.name} Reward`}
        />
      )}
      {partner.logo && (
        <div className=" absolute top-6 right-6 w-12 h-12 p-0.5 overflow-hidden bg-white border rounded-full flex-shrink-0">
          <GatsbyImage
            image={partner.logo.childImageSharp.gatsbyImageData}
            objectFit="contain"
            objectPosition="50% 50%"
            className="w-full h-full overflow-hidden rounded-full"
            imgClassName="rounded-full"
            alt={`${partner.name} Logo`}
          />
        </div>
      )}
      <div className="flex flex-col justify-center flex-grow space-y-2 p-x-2">
        <p className="text-sm font-bold text-gray-500 uppercase">
          {partner.name}
        </p>
        <h4 className="flex-grow">{reward.title}</h4>
        <div className="flex flex-row items-center mb-2 space-x-1 text-green-500">
          <p className="text-sm font-bold">{reward.price.amount}</p>
          <PlanetLeaf className="fill-current" />
        </div>
      </div>
    </Link>
  );
};

export default RewardCard;
