import { Trans } from "gatsby-plugin-react-i18next";
import React from "react";
import { useGlobalState } from "../state/globalState";

export interface CashbackRateLabelProps {
  className?: string;
  rate: {
    rateUnscaled: number;
    rateScale: number;
  };
}

export interface CashbackRateLabelContentProps {
  rate: {
    rateUnscaled: number;
    rateScale: number;
  };
}

const CashbackRateLabel: React.FC<CashbackRateLabelProps> = ({
  className,
  rate,
}) => {
  const [utmTags] = useGlobalState("utmTags");

  const [isSignupPopupOpen, setIsSignupPopupOpen] =
    useGlobalState("isSignupPopupOpen");

  const handleOpenSignupPopup = React.useCallback(() => {
    setIsSignupPopupOpen(true);
  }, []);

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );

  return (
    <div className={className}>
      <a
        href={`/download${utmTags && utmTags !== "" ? "?" : ""}${utmTags}`}
        className="block w-full md:hidden"
      >
        <LabelContent rate={rate} />
      </a>
      <button
        className="hidden w-full sm:block"
        onClick={() => {
          setIsDownloadPopupOpen(true);
        }}
      >
        <LabelContent rate={rate} />
      </button>
    </div>
  );
};

const LabelContent: React.FC<CashbackRateLabelContentProps> = ({ rate }) => {
  return (
    <div className="flex items-center justify-center w-full py-2 text-green-500 bg-green-100 rounded md:py-3">
      <Trans
        i18nKey="partners:cashback"
        count={(rate.rateUnscaled / Math.pow(10, rate.rateScale)) * 100}
      >
        <span className="mr-1">Verdiene</span>
        <span className="font-bold">5% Cashback</span>
      </Trans>
    </div>
  );
};

export default CashbackRateLabel;
