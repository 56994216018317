import cx from "classnames";
import React from "react";

interface ProgressBarProps {
  percent: number;
  disabled?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ disabled, percent }) => {
  return (
    <div className="h-2 bg-gray-200 rounded-md">
      <div
        className={cx("h-2 rounded-md", {
          "bg-green-300": !disabled,
          "bg-gray-400": disabled,
        })}
        style={{
          width: `${percent.toFixed()}%`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
