import classNames from "classnames";
import React from "react";

export interface BackgroundGradientProps
  extends React.StyleHTMLAttributes<HTMLDivElement> {}

const BackgroundGradient: React.FC<BackgroundGradientProps> = ({
  className,
}) => {
  return (
    <div
      className={classNames([
        className,
        "absolute bg-easing-b-navigation-shadow mix-blend-multiply",
      ])}
    />
  );
};

export default BackgroundGradient;
