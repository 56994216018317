import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { useGlobalState } from "../state/globalState";
import DownloadButtons from "./DownloadButtons";

export interface DownloadTeaserBigProps {
  className?: string;
}

export interface IQueryData {
  site: {
    siteMetadata: { downloadActive: boolean; description: string };
  };
  image: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

const query = graphql`
  query DownloadTeasersQuery {
    site {
      siteMetadata {
        downloadActive
        description
      }
    }
    image: file(relativePath: { eq: "images/teaser/teaser-square.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
  }
`;

const DownloadTeaserBig: React.FC<DownloadTeaserBigProps> = ({ className }) => {
  const { t } = useTranslation("teaser");
  const data: IQueryData = useStaticQuery(query);

  const [utmTags] = useGlobalState("utmTags");

  const [isSignupPopupOpen, setIsSignupPopupOpen] =
    useGlobalState("isSignupPopupOpen");

  const handleOpenSignupPopup = React.useCallback(() => {
    setIsSignupPopupOpen(true);
  }, []);

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );

  return (
    <div className={classNames(["py-24 bg-white container-7xl", className])}>
      <div className="z-0 flex flex-col overflow-hidden transition-shadow duration-300 bg-gray-100 shadow-lg translate-z-0 sm:flex-row rounded-2xl hover:shadow-xl">
        <div className="relative flex-shrink-0 order-1 w-full h-56 bg-green-500 sm:w-64 sm:h-64">
          <div className="w-full aspect-w-1 aspect-h-1" />
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            alt="Coins teaser image"
            className="!absolute !inset-0 object-fill"
            imgClassName="rounded-t-2xl sm:rounded-tr-none sm:rounded-l-2xl"
          />
        </div>
        <div className="flex flex-col items-center justify-center order-2 p-6 sm:items-start">
          <h2 className="mb-2 text-2xl ">{t("teaser:download.title")}</h2>
          <p className="mb-4 text-gray-500">
            {data.site.siteMetadata.description}{" "}
            <span className="hidden font-bold md:visible">
              {t("teaser:download.subline")}
            </span>
          </p>
          {data.site.siteMetadata.downloadActive ? (
            <>
              <a
                className="button-green-filled md:hidden"
                href={`/download${
                  utmTags && utmTags !== "" ? "?" : ""
                }${utmTags}`}
              >
                {t("common:download.general")}
              </a>

              <DownloadButtons
                className="hidden md:block"
                buttonClassName="h-10"
                onClick={() => {
                  setIsDownloadPopupOpen(true);
                }}
              />
            </>
          ) : (
            <>
              <button
                onClick={handleOpenSignupPopup}
                className="w-full px-10 py-3 text-sm font-medium tracking-wide text-white uppercase bg-green-500 rounded-lg md:hidden hover:shadow-md font-headline"
              >
                {t("teaser:download.cta_download")}
              </button>

              <DownloadButtons onClick={handleOpenSignupPopup} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DownloadTeaserBig;
