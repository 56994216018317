import React from "react";

const PlanetLeaf = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75815 2.95111C5.71559 2.33621 6.82809 2.00634 7.96584 2H14V7.99887C14 9.13765 13.676 10.2529 13.066 11.2144C12.456 12.1759 11.5852 12.9439 10.5551 13.4288C9.5251 13.9137 8.37841 14.0954 7.249 13.9527C6.11959 13.81 5.05407 13.3488 4.1769 12.623L2.94216 13.8407C2.83603 13.9309 2.69979 13.9777 2.56066 13.9718C2.42152 13.966 2.28972 13.9078 2.19156 13.809C2.09341 13.7102 2.03612 13.578 2.03114 13.4387C2.02615 13.2995 2.07384 13.1635 2.16468 13.058L3.39319 11.8465C2.6642 10.9726 2.19864 9.90939 2.05084 8.78087C1.90304 7.65234 2.07909 6.50504 2.55844 5.47281C3.0378 4.44059 3.80071 3.56601 4.75815 2.95111ZM9.96311 7.84306V7.82561C10.7032 7.57633 11.1891 7.18123 11.1891 6.47702C11.1891 5.51107 10.302 4.79813 8.72463 4.65854V3.76737H7.61698V4.65854C6.79378 4.73095 5.99848 4.99287 5.29328 5.42382L5.64962 6.50319C6.38808 6.04596 7.24337 5.81301 8.11162 5.83264C9.22426 5.83264 9.80612 6.15545 9.80612 6.59044C9.80612 7.02543 9.28531 7.35946 7.34536 7.35946V8.35657C9.38997 8.35657 9.97183 8.65197 9.97183 9.22904C9.97183 9.75003 9.35509 10.1763 8.12782 10.1763C7.24448 10.1851 6.3745 9.96022 5.60601 9.52444L5.26711 10.6462C5.98722 11.0527 6.79133 11.2878 7.61698 11.3329V12.2204H8.72463V11.3267C10.5562 11.1909 11.3984 10.4231 11.3984 9.31255C11.3984 8.50365 10.8677 8.07738 9.96311 7.84306Z"
      />
    </svg>
  );
};

export default PlanetLeaf;
