import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import AssessmentCard from "../components/AssessmentCard";
import BackgroundGradient from "../components/BackgroundGradient";
import BackToOverview from "../components/BackToOverview";
import RewardCard from "../components/cards/RewardCard";
import RewardCardEmpty from "../components/cards/RewardCardEmpty";
import CashbackRateLabel from "../components/CashbackRateLabel";
import DownloadTeaserBig from "../components/DownloadTeaserBig";
import SocialMediaTeaser from "../components/SocialMediaTeaser";
import { platformMatcher } from "../helpers/platformMatcher";
import { useGlobalState } from "../state/globalState";
import { IPartner, IReward } from "../types/gatsby";

export interface PartnerDetailPageProps {
  data: {
    partner: IPartner;
    rewards: {
      edges: {
        node: IReward;
      }[];
    };
  };
  context: {
    partnerId: string;
  };
}

const PartnerDetailPage: React.FC<PartnerDetailPageProps> = ({
  data: { partner, rewards },
}) => {
  const { t } = useTranslation(["partners"]);

  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "dark",
      hidden: false,
    });
  }, []);

  const partnerLinks = React.useMemo(
    () =>
      partner.socialLinks.reduce<{ [key: string]: string }>((acc, link) => {
        if (link === "") return acc;
        for (const platform in platformMatcher) {
          if (link.match(platformMatcher[platform])) {
            acc[platform] = link;
            return acc;
          }
        }
        acc.web = link;
        return acc;
      }, {}),
    []
  );

  return (
    <div>
      <div className="relative w-full overflow-hidden h-96 md:h-128 lg:h-192">
        {partner.coverImage_localImage ? (
          <GatsbyImage
            image={
              partner.coverImage_localImage.childImageSharp.gatsbyImageData
            }
            // loading="eager"
            className="w-full h-full"
            alt="Forest background image"
          />
        ) : (
          <div className="w-full h-full bg-gray-300" />
        )}
        <BackgroundGradient className="top-0 w-full h-40" />
      </div>

      <section className="flex flex-col justify-center px-12 py-12 space-y-6 container-4xl">
        <div className="mb-6">
          <BackToOverview
            to={"/our-partners/partners"}
            cta={t("partners:cta_show_partner_overview")}
          />
        </div>
        <div className="grid grid-cols-2 gap-6 md:gap-12">
          <div className="flex flex-row items-center col-span-2 space-x-4 md:col-span-1">
            <div className="w-12 h-12 p-0.5 overflow-hidden bg-white border rounded-full flex-shrink-0">
              {partner.logo_localImage && (
                <GatsbyImage
                  image={
                    partner.logo_localImage.childImageSharp.gatsbyImageData
                  }
                  objectFit="contain"
                  objectPosition="50% 50%"
                  className="w-full h-full overflow-hidden rounded-full"
                  alt={`${partner.name} Logo`}
                />
              )}
            </div>

            <div className="flex-grow">
              <h1 className="uppercase">{partner.name}</h1>
              <p>{partner.tagLine}</p>
            </div>

            <a
              href={partnerLinks.web}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.9994 0.969928C17.9834 0.431516 17.542 -4.9895e-05 16.9997 0L12 0.000460087L11.9699 0.000904801C11.4315 0.0168275 11 0.458309 11.0001 1.00055L11.0005 1.03057C11.0164 1.56897 11.4579 2.00051 12.0001 2.00046L14.5854 2.0001L7.29292 9.29338L7.2734 9.31345C6.90252 9.70532 6.90907 10.3237 7.29298 10.7076C7.6835 11.0981 8.31669 11.0981 8.70719 10.7075L15.9999 3.4141L16.0001 6.00051L16.0005 6.03053C16.0164 6.56893 16.4579 7.00049 17.0001 7.00046C17.5524 7.00043 18.0001 6.55269 18.0001 6.00041L17.9998 0.999948L17.9994 0.969928ZM8 1.00044C8 0.448158 7.55228 0.000442986 7 0.000442986H2L1.96555 0.000733692C0.876873 0.0191205 0 0.907379 0 2.00044V16.0004L0.000290706 16.0349C0.0186775 17.1236 0.906936 18.0004 2 18.0004H16L16.0344 18.0002C17.1231 17.9818 18 17.0935 18 16.0004V11.0004L17.9996 10.9725C17.9848 10.4331 17.5429 10.0004 17 10.0004C16.4477 10.0004 16 10.4482 16 11.0004V16.0004H2V2.00044H7L7.02799 2.00006C7.56733 1.98524 8 1.54337 8 1.00044Z"
                />
              </svg>
            </a>
          </div>
          {partner.fields.cashbackRate && (
            <CashbackRateLabel
              rate={partner.fields.cashbackRate}
              className="col-span-2 md:col-span-1"
            />
          )}
        </div>
        <div className="z-10 prose-lg text-gray-500">
          <p>{partner.description}</p>
        </div>
        {partner.fields.impactRating && (
          <div className="py-12">
            <AssessmentCard assessment={partner.fields.impactRating} />
            <div className="max-w-sm mx-auto mt-4 text-sm text-center text-gray-500">
              <Trans
                i18nKey="partners:assessment.moreInfo"
                partner={partner.name}
                values={{
                  score: partner.fields.impactRating.score,
                  partner: partner.name,
                }}
              >
                Partner wurde mit <span className="font-bold">99 Punkten</span>{" "}
                bewertet. Wir bewerten Marken auf einer Skala von 0 bis 100.
              </Trans>
              <br />
              <Link
                to="/blog/about-our-impact-rating"
                className="block mt-2 text-green-500 "
              >
                {t("partners:assessment.cta")}
              </Link>
            </div>
          </div>
        )}
        <div>
          <h2 className="mb-6 text-lg">{t("partner:shared_contents")}</h2>
          <div className="grid grid-cols-2 gap-6">
            {partner.moodImages_localImages
              .slice(0, 4)
              .map((image) =>
                image ? (
                  <GatsbyImage
                    key={image.id}
                    image={image.childImageSharp.gatsbyImageData}
                    className="h-64 col-span-2 sm:col-span-1 rounded-2xl"
                    imgClassName="rounded-2xl"
                    alt={`${partner.name} Mood`}
                  />
                ) : null
              )}
          </div>
        </div>
      </section>

      <section className="bg-gray-100">
        <div className="pt-16 container-5xl">
          <h2 className="text-2xl leading-8 text-center text-primary font-headline">
            {t("partners:rewards_single")}
          </h2>
        </div>
        <div className="px-4 py-16 overflow-visible container-4xl">
          <div className="grid grid-cols-8 gap-8">
            {rewards.edges.length > 0 ? (
              rewards.edges.map(({ node: reward }) => (
                <div
                  key={reward.id}
                  className={classNames({
                    "col-span-8 sm:col-span-4": true,
                    "sm:col-start-3": rewards.edges.length === 1,
                  })}
                >
                  <RewardCard
                    reward={reward}
                    partner={{ ...partner, logo: partner.logo_localImage }}
                  />
                </div>
              ))
            ) : (
              <div className="col-span-8 sm:col-span-4 sm:col-start-3">
                <RewardCardEmpty partner={partner} />
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="py-12 bg-white container-4xl">
        <SocialMediaTeaser />
      </div>

      <DownloadTeaserBig className="pt-0" />
    </div>
  );
};

export default PartnerDetailPage;

export const partnerDetailQuery = graphql`
  query PartnerDetailQuery(
    $language: String!
    $partnerId: String!
    $dateNow: Date!
  ) {
    partner: plan3TPartner(alternative_id: { eq: $partnerId }) {
      id
      name
      tagLine
      description
      socialLinks
      logo_localImage {
        id
        childImageSharp {
          gatsbyImageData(
            width: 120
            quality: 80
            placeholder: BLURRED
            blurredOptions: { width: 40 }
          )
        }
      }
      coverImage_localImage {
        id
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 90
            placeholder: BLURRED
            blurredOptions: { width: 200 }
          )
        }
      }
      moodImages_localImages {
        id
        childImageSharp {
          gatsbyImageData(
            width: 250
            quality: 90
            placeholder: BLURRED
            blurredOptions: { width: 72 }
          )
        }
      }
      fields {
        cashbackRate {
          rateUnscaled
          rateScale
        }
        impactRating {
          partnerId
          scoringWord
          scoringWordLabel
          score
          scoreMax
          scores {
            label
            valueUnscaled
            valueScale
            applicable
          }
        }
      }
    }
    rewards: allPlan3TReward(
      filter: { partnerId: { eq: $partnerId }, validUntil: { gt: $dateNow } }
    ) {
      edges {
        node {
          id
          title
          rewardId
          partnerId
          price {
            amount
          }
          localImage {
            id
            childImageSharp {
              gatsbyImageData(
                width: 250
                quality: 70
                placeholder: BLURRED
                blurredOptions: { width: 72 }
              )
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
