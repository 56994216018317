import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { IPartner } from "../../types/gatsby";

export interface RewardCardEmptyProps {
  partner: IPartner;
}

const RewardCardEmpty: React.FC<RewardCardEmptyProps> = ({ partner }) => {
  const { t } = useTranslation("partners");
  return (
    <div className="relative flex flex-col col-span-6 p-4 border border-gray-200 border-dotted rounded-2xl md:col-span-3 lg:col-span-2">
      <div className="flex flex-col items-center justify-center w-full h-48 p-12 bg-gray-200 rounded-lg">
        <h4 className="mb-2 text-center text-primary">
          {t("partners:reward_empty_title", { partner: partner.name })}
        </h4>
        <p className="text-center text-gray-500">
          {t("partners:reward_empty_description")}
        </p>
      </div>
    </div>
  );
};

export default RewardCardEmpty;
