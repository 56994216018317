import classNames from "classnames";
import React from "react";
import ProgressBar from "./ProgressBar";

export interface AssessmentCardProps {
  assessment: {
    score: "empty" | number;
    scoreMax: "empty" | number;
    scores: ScoreProps["score"][];
  };
}

const AssessmentCard: React.FC<AssessmentCardProps> = (
  props: AssessmentCardProps
) => {
  const score = "empty" in props ? "TBD" : props.assessment.score;
  const scoreMax = "empty" in props ? "100" : props.assessment.scoreMax;

  return (
    <div className="max-w-sm p-6 mx-auto bg-gray-100 rounded-2xl">
      <div className="flex flex-col w-full space-y-6 divide-y divide-gray-200">
        <div className="flex flex-col items-center space-y-1">
          <h1>
            <span className="text-2xl font-bold text-gray-700">{score}</span>{" "}
            <span className="text-lg font-bold text-gray-500">
              / {scoreMax}
            </span>
          </h1>
          <p className="text-xs font-bold text-gray-500">PLAN3T SCORE</p>
        </div>
        <div className="flex flex-col pt-6 space-y-4">
          {"assessment" in props &&
            props.assessment.scores
              .filter((score) => score.applicable)
              .map((score) => <Score key={score.label} score={score} />)}
          {!("assessment" in props) &&
            ["Climate", "People", "Transparency", "Resources"]
              .map((label) => ({
                label,
                valueUnscaled: 5,
                valueScale: 1,
                applicable: true,
              }))
              .map((score) => (
                <Score key={score.label} score={score} disabled />
              ))}
        </div>
      </div>
    </div>
  );
};

export interface ScoreProps {
  score: {
    valueUnscaled: number;
    valueScale: number;
    label: string;
    applicable: boolean;
  };
  disabled?: boolean;
  className?: string;
}

const Score: React.FC<ScoreProps> = ({ score, disabled, className }) => {
  const scorePercent =
    (score.valueUnscaled / Math.pow(10, score.valueScale)) * 100;

  return (
    <div
      className={classNames(
        "flex flex-row items-center justify-between",
        className
      )}
    >
      <h6 className="flex-shrink-0 w-1/2 text-xs font-bold text-gray-500">
        {score.label.toUpperCase()}
      </h6>
      <div className="w-1/2">
        <ProgressBar percent={scorePercent} disabled={disabled} />
      </div>
    </div>
  );
};

export default AssessmentCard;
